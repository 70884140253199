import React from 'react';
import { useForm } from 'react-hook-form';
import swal from "sweetalert";

const TotalPlay = (props) => {
    const { register, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        fetch('/api/Account/IsEnrollmentValid?companyName=' + props.company + '&enrollment=' + data.Enrollment,
            { method: 'GET' })
            .then(function (response) {
                if (response.ok) {
                    return response.json()
                }
            })
            .then(function (result) {
                console.log(result)
                if (result.Status) {
                    e.target.reset();
                    props.setEnrollment(data.Enrollment);
                    props.setShowLogeo();
                } else {
                    swal({ text: result.Message, icon: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
                swal({ text: "Error", icon: "error" });
            });
    }

    return (
        <div className="content_logeo login">
            <div className="pt-3">
                <h5 className="ttls text-center mb-2">Registro Totalplay</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label for="enrollment">Ingresa tu número de contrato de Totalplay</label>
                                <input type="text" className="form-control" name="Enrollment" id="enrollment" required ref={register} />
                            </div>
                        </div>
                    </div>
                    <p className="text-center">
                        <button type="submit" className="btn">Continúa con tu registro</button>
                    </p>
                </form>
            </div>
        </div>
    )
}
export default TotalPlay;