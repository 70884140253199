import React, { Component } from 'react';
import ShoppingHistoryWrapper from '../components/shoppingHistoryWrapper';
import $ from 'jquery';

export class ShoppingHistory extends Component {
    static displayName = ShoppingHistory.name;
    constructor(props) {
        super(props);
        this.state = {
            shoppingHistory: []
        };
    }

    componentWillMount() {
        this.shoppingHistory();
    }

    componentDidMount() {
        $('html, body').scrollTop(0);
    }

    shoppingHistory = () => {
        fetch("/api/PurchaseOrder/Index", { method: 'GET' })
            .then(response => response.json())
            .then(result => this.setState({ shoppingHistory: result }))
            .catch(error => console.log('error', error));
    }

    render() {
        return (
            <div className="container">
                <ShoppingHistoryWrapper shoppingHistory={this.state.shoppingHistory} />
            </div>
        );
    }
}