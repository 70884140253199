import React from 'react';
import './purchasedItemDetails.scss';
import { useSelector } from 'react-redux';

const PurchasedItemDetails = (props) => {
    const countrySetting = useSelector(state => state.countrySetting);

    return (
        <div className="content_item_shopping">
            <div className="row align-items-center">
                <div className="col-md-3">
                    <span className="bg_logo_shop">
                        <img src={props.Logo} alt="" />
                    </span>
                </div>
                <div className="col-md-9">
                    <p className="item_purchased box_flex">
                        <span>({props.Quantity})</span>
                        <span>{props.Description}</span>
                        <span>{countrySetting.currency}{(Number(props.SubTotal)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PurchasedItemDetails;