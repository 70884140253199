import React, { Component } from "react";
import Slider from "react-slick";
import { SliderItemProvider } from './sliderItemProvider'
import './sliderProvider.scss'

export class SliderProvider extends Component {
    static displayName = SliderProvider.name;

    render() {

        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 800,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div className="wrapper_slider_provider">
                <Slider {...settings}>
                    <div className="item_provider">
                        <img src="./imgs/items_provider/curso-1.jpg" alt="" />
                    </div>
                    <div className="item_provider">
                        <img src="./imgs/items_provider/curso-2.jpg" alt="" />
                    </div>
                    <div className="item_provider">
                        <img src="./imgs/items_provider/curso-3.jpg" alt="" />
                    </div>
                    <div className="item_provider">
                        <img src="./imgs/items_provider/curso-4.jpg" alt="" />
                    </div>
                    {/*<SliderItemProvider />
          <SliderItemProvider />
          <SliderItemProvider />
          <SliderItemProvider />
          <SliderItemProvider />*/}
                </ Slider>
            </div>
        )
    }
} 