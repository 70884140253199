import React, { Component } from "react"

export class CreateInvoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceInfoConfigs: []
        }
    }

    componentWillMount() {
        this.createInvoice();
    }

    createInvoice = () => {
        fetch("/api/PaymentMethod/CreateInvoice", { method: 'GET' })
            .then(response => response.json())
            .then(result => this.setState({ invoiceInfoConfigs: result.InvoiceInfoConfigs }))
            .catch(error => {
                console.log('error', error);
            });
    }

    sendData = (e) => {
        e.preventDefault()
        document.getElementById("formInvoice").submit()
    }


    renderControls = () => {
        let elements = new Array();
        this.state.invoiceInfoConfigs.forEach(control => {
            let id = control.Id && control.Id != "" ? control.Id : undefined;
            let label = <label for={id}>{control.Label}</label>;
            let value = control.PreviousValue && control.PreviousValue != "" ? control.PreviousValue : undefined;

            let controlType;
            switch (control.ControlType.toLowerCase()) {
                case "select":
                    let options = control.InvoiceSelectValues.map(o => <option value={o.Value}>{o.Key}</option>);
                    controlType = <select id={id} name={id} defaultValue={value} className="form-control" required>{options}</select>;
                    break;
                case "input":
                    controlType = <input type="text" id={id} name={id} defaultValue={value} className="form-control" required />;
                    break;
            }

            elements.push(<div className="form-group mb-4">{label}{controlType}</div>);
        });

        return elements
    }

    render() {
        const style = { bg_picture: { background: 'url(./imgs/bglegal.jpg)' } };

        return (
            <section className="wrapper_sections hg_top">
                <h1 className="ttls fw700 text-center mb-3 c_primary">SOLICITA TU FACTURA</h1>
                <div className="contain_sections">
                    <div className="row">
                        <div className="col-6 ">
                            <div className="box_info_sections">
                                <h6 className="fw300">Recuerda que tu factura llegará 4 horas después de haberla solicitado.</h6>
                                <hr />
                                <form id="formInvoice" method="post" action="/api/PaymentMethod/UpdateUserData">
                                    <input type="hidden" id="HdfID" name="HdfID" value={this.props.match.params.id} />
                                    {this.renderControls()}
                                    <button type="submit" className="btn">Enviar</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-6">
                            <div
                                className="box_picture"
                                style={style.bg_picture}
                            ></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}