import React, { Component } from "react";

import './sliderItemProvider.scss'

export class SliderItemProvider extends Component {
  static displayName = SliderItemProvider.name;

  render() {
    return (
      <div className="item_provider">
        <img src="./imgs/items_provider/curso1.jpg" alt="" />
      </div>
    )
  }
} 