import React from 'react';

const NoticePrivacyGuatemala = () => {
    return (
        <div className="box_legal">
            <p>Red Voucher Systems Guatemala, S.A. (en adelante “Red Voucher”), será el responsable de recabar, almacenar y proteger los datos personales y financieros que le sean proporcionados voluntariamente por las personas físicas o jurídicas (en adelante el "USUARIO"). La persona encargada del manejo de datos personales es Nery Roberto Muñoz, la cual puede ser contactada en el siguiente correo electrónico nmuñoz@infoconsult.pro, o bien, en el domicilio de Red Voucher.</p>
            <p>El tratamiento de los datos personales del USUARIO que se han puesto a disposición de Red Voucher bajo cualquier forma o circunstancia, podrán ser efectuados de conformidad con los términos y condiciones del presente Aviso de Privacidad, por lo que desde este momento se entiende, que el USUARIO otorga expresamente su consentimiento para dicho tratamiento.  </p>
            <p>La información de datos personales, que es recolectada por Red Voucher de manera directa del USUARIO con su pleno consentimiento, tendrá el uso que en forma enunciativa pero no limitativa se describe a continuación: identificarlo, ubicarlo, comunicarle, contactarse, enviarle información y/o mercancía, facilitar medio de pago, y/o propósitos estadísticos y de análisis.  </p>
            <p>En la recolección de datos personales Red Voucher sigue todos los principios de: licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad. Es responsabilidad del USUARIO, el garantizar que los datos facilitados voluntariamente a Red Voucher sean correctos, veraces y completos, siendo responsable del USUARIO comunicar a Red Voucher cualquier modificación en los mismos a efecto de que se pueda cumplir con la obligación de mantener la información actualizada. El USUARIO tendrá derecho a solicitar el acceso, rectificación, cancelación y/u oposición de sus datos, mediante solicitud formulada al correo electrónico, soporte@redcompanies.com.gt.</p>
            <p>Los datos personales del USUARIO pueden ser transferidos y tratados dentro y fuera del país, por personas distintas a esta empresa, en el sentido que la información (excluidos datos financieros) puede ser compartida con nuestros anunciantes, patrocinadores, publicistas, contratistas, socios comerciales y/o terceros con quien Red Voucher tenga celebrados o celebre diversos acuerdos comerciales tanto en territorio nacional como en el extranjero, para enviar, a discreción de Red Voucher, notificación de ofertas, avisos, publicidad y/o mensajes promocionales de acuerdo a sus intereses específicos, ya sean propios o de terceros, proporcionar datos estadísticos a anunciantes potenciales o llevar a cabo comunicaciones con fines de mercadotecnia, publicitarios o telemarketing; realizar investigaciones de mercadeo; realizar programas de beneficios e incentivos y en general para mejorar nuestros servicios. Los datos personales del USUARIO también pueden transferirse, almacenarse y procesarse en un país distinto de donde se proporcionó. Sí lo hacemos, transferimos la información de conformidad con las leyes aplicables. Tomamos medidas para proteger la información personal sin importar el país donde se almacena o a donde se transfiere. Tenemos procedimientos y controles oportunos para procurar esta protección. A excepción de lo establecido en el presente párrafo, los datos personales proporcionados por el USUARIO a Red Voucher no serán divulgados ni compartidos con terceros -no habrá transferencia de datos-, ni se les dará un uso distinto al señalado en el presente Aviso de Privacidad, salvo que medie una modificación del mismo.</p>
            <p>La información que sea entregada a Red Voucher, será debidamente resguardada, conservada y protegida, con los medios tecnológicos y físicos adecuados a efecto de que se impida su pérdida, mal uso, alteración, acceso no autorizado y robo. Solo tendrán acceso a la información aquellas personas autorizadas por Red Voucher, quienes han asumido el compromiso de mantener la información bajo un estricto orden de confidencialidad y seguridad. Sin embargo, Red Voucher no garantiza que terceros no autorizados no puedan tener acceso a los sistemas físicos o lógicos de Red Voucher o a los documentos electrónicos y archivos almacenados en sus sistemas. Por lo tanto, Red Voucher no será responsable en ningún caso de los daños y perjuicios que se puedan derivar de dicho acceso no autorizado.</p>
            <p>La temporalidad del manejo de los datos personales del USUARIO, dependerá de la relación jurídica que se tenga celebrada con Red Voucher, así como de las obligaciones exigidas por la legislación vigente y las autoridades competentes. En todos los casos la información será guardada solo por el tiempo razonable. </p>
            <p>En caso de que los datos personales resguardados sean requeridos por una autoridad gubernativa o judicial, ya sea por proceso legal, para responder a cualquier reclamo o acciones legales, o para proteger los derechos de Red Voucher o sus clientes y el público, se podrán a disposición dentro del estricto cumplimiento a la ley y/o en los casos que esta normatividad así lo prevea.</p>
            <p>Red Voucher puede recabar datos a través de sus sitios web, o mediante el uso de herramientas de captura automática de datos. Dichas herramientas le permiten recolectar la información que envía su navegador a dichos sitios web, tales como el tipo de navegador que utiliza, el idioma de usuario, los tiempos de acceso y la dirección IP de sitios web que utilizó para acceder a los sitios del responsable o sus encargados. Estos datos se recaban con la finalidad identificar al cliente y brindarle un mejor servicio durante el tiempo que usa la página web.</p>
            <p>Red Voucher se reserva el derecho de modificar o actualizar el presente Aviso de Privacidad en cualquier momento, para la atención, adecuación y cumplimiento de las modificaciones legales, que en su caso, sean aplicables. Red Voucher, entenderá que de no expresar lo contrario, significa que el USUARIO ha leído, entendido y acordado los términos ahí expuestos, lo que constituye su consentimiento a los cambios establecidos en dichas actualizaciones respecto al tratamiento de sus datos personales.</p>
            <p>Con el presente Aviso de Privacidad, el USUARIO queda debidamente informado de los datos que se recabaron de éste y con qué fines, aceptando los términos contenidos que fueron elaborados en cumplimiento a la ley.</p>
        </div>
    )
}

export default NoticePrivacyGuatemala;