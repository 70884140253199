import React, { Component } from "react";
import { Link } from 'react-router-dom';


export class ItemProviderIndividual extends Component {
  static displayName = ItemProviderIndividual.name;

  render() {
    return (
      <div className="col-sm-6 mb-5">
        <Link
          to='/'
          className="item_provider_individual"
        >
          <img src="./imgs/item_provider.jpg" alt="Item Proveedor" />
        </Link>
      </div>
    )
  }
} 