import React, { useState } from 'react'
import { Modal } from 'reactstrap';
import './videoProvider.scss'

const VideoProvider = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return <React.Fragment>

        <div className="box_video_provider">
            <img src={props.urlImage} alt="Provider" />
            {
                props.urlVideo != "" ?
                    <span
                        className="ico_play"
                        onClick={toggle}
                    >
                        <i className="fas fa-play"></i>
                    </span>
                    : ""
            }
        </div>

        <Modal
            className='wrapper_modal_iframe'
            isOpen={modal}
            toggle={toggle}
        >
            <div className="content_iframe_video">
                <span className="btn_close_modal trans" onClick={toggle}><i className="fas fa-times-circle"></i> Cerrar</span>

                <div className="iframe_video">
                    <iframe width="560" height="315" src={props.urlVideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

            </div>
        </Modal>

    </React.Fragment>

}


export default VideoProvider;