import React from 'react';
import { Link } from 'react-router-dom';
 
const PaymentCanceled = () => {
  return (
    <div className="wrapper_sections hg_top">
      <div className="contain_sections text-center cancel">
        <div className="row">
          <div className="col-12">
            <div className="box_cancel">
              <p className="cancel_label">CANCELACIÓN</p>
              <div className="cancel_box_icons">
                <span><i className="far fa-frown"></i></span>
                <h1>¡Oops!</h1>
              </div>
              <div className="cancel_info">
                <h4 className="ttls_sections">Algo salió mal con tu orden</h4>
                <p className>Ocurrió un problema con el pago de tu orden, ha sido cancelada o rechazada por el banco.</p>
              </div>
              <p className="mt-4">
                <Link className="btn" to="/">Ir a inicio</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentCanceled