import React from 'react'
import './wrapperBullets.scss'

const WrapperBullets = () => {
    return (
        <div className="wrapper_bullets">
            <div className="text-center">
                <h5 className="ttls fw500 m-0">¿Por qué debemos comprar en?</h5>
                <img src="./imgs/redvoucher_clrs.png" alt="RedVoucher" />
            </div>
            <div className="row text-center">
                <div className="col-6 col-md-3 box_item_bullets">
                    <div>
                        <img src="./imgs/bullets/bullet-1.png" alt="" />
                    </div>
                    <h5>Beneficios inigualables</h5>
                    <p>Adquiere tus productos a precios increíbles, en comparación con los precios comerciales.</p>
                </div>
                <div className="col-6 col-md-3 box_item_bullets">
                    <div>
                        <img src="./imgs/bullets/bullet-2.png" alt="" />
                    </div>
                    <h5>Opciones para todos</h5>
                    <p>Encuentra las marcas líderes en diversión y entretenimiento para todas las edades.</p>
                </div>
                <div className="col-6 col-md-3 box_item_bullets">
                    <div>
                        <img src="./imgs/bullets/bullet-3.png" alt="" />
                    </div>
                    <h5>Diversión al instante</h5>
                    <p>Todas tus compras y cupones son inmediatos y enviados vía electrónica.</p>
                </div>
                <div className="col-6 col-md-3 box_item_bullets">
                    <div>
                        <img src="./imgs/bullets/bullet-4.png" alt="" />
                    </div>
                    <h5>Donde y cuando quieras</h5>
                    <p>Compra y canjea tus descuentos en el momento que quieras y en el lugar donde te encuentres.</p>
                </div>
            </div>

        </div>
    )
}

export default WrapperBullets;