import React, {useState} from 'react'
import './favoriteCheck.scss'

const FavoriteCheck = () => {

  const [activeFav, stateFav] = useState('far fa-heart')
  
  function BtnFavorites() {
    if (activeFav === 'far fa-heart') {
      stateFav('fas fa-heart btn_animated')
    } else if (activeFav === 'fas fa-heart btn_animated') {
      stateFav('far fa-heart')
    }
  }

  return (
    <div className="wrapper_favs_check">
      <div className="content_favs_check">
        <span className="boxes_favs" onClick={BtnFavorites}>
          <div className="box_label_favs">
            Agregar a favoritos
        </div>
          <div className="box_ico_favs ">
            <span><i className={` ${activeFav}`}></i></span>
          </div>
        </span>
      </div>
    </div> 
  )
}


export default FavoriteCheck;