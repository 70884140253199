import React, { Component } from 'react';
import { useForm } from 'react-hook-form'
import swal from "sweetalert"

    
const  ForgotPassword = () => {
    const { register, handleSubmit } = useForm();
    
    const onSubmit = (data, e) => {
        fetch('/api/Account/ForgotPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            if (response.ok) {
                return response.json()
            }
        }).then(function (result) {
            if (result.Status) {
                e.target.reset();
                swal({ text: result.Message, icon: "success" });
            } else {
                swal({ text: result.Message, icon: "error" });
            }
        }).catch(function (error) {
            console.log(error);
            swal({ text: "Error", icon: "error" });
        });
    }
 

 

    const style = {
      bg_picture: { background: 'url(./imgs/bg_forgotpass.jpg)' }
    }

    return (
      <section className="wrapper_sections hg_top">
        <h1 className="ttls fw700 text-center mb-1 font-italic c_primary">¡Tranquilo!</h1>
        <h4 className="ttls fw300 text-center mb-4">Restablece tu contraseña aquí</h4>
        <div className="contain_sections">
          <div className="row">
            <div className="col-6 ">
              <div className="box_info_sections">
                  <h6 className="fw300">Ingresa tu correo electrónico y te enviaremos las instrucciones sobre cómo restablecerla.</h6>
                            <hr/>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-4">
                    <label for="email_user">Correo electrónico</label>
                                    <input type="email" className="form-control" id="Email" name="Email" aria-describedby="emailHelp" placeholder="example@mail.com" required ref={register} />
                  </div>

                  <button type="submit" className="btn">Enviar instrucciones</button>
                </form>
              </div>

            </div>
            <div className="col-6">
              <div
                className="box_picture"
                style={style.bg_picture}
              ></div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default ForgotPassword;
