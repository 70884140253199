import React from 'react';

const TermsAndConditionsGuatemala = () => {
    return (
        <div className="box_legal">
            <p>Los presentes Términos y Condiciones regulan el uso del servicio Red Voucher sitio web y aplicación móvil www.redvoucheronline.com.gt (en adelante el "Sitio Web"), del que Red Voucher Systems Guatemala (en lo sucesivo “Red Companies”) es titular. Red Companies tiene su domicilio en 4ta. Avenida 15-70 Zona 10, Edificio Paladium, Oficina 13-C, ciudad de Guatemala. </p>
            <p>Al utilizar este servicio podrás comprar tus folios electrónicos para ser redimidos por servicios y/o productos bajo las condiciones y disponibilidad establecidos en el Sitio Web. </p>
            <p>Red Companies se reserva el derecho, de cambiar, modificar o remover total o parcialmente los presentes Términos y Condiciones en cualquier momento. Es responsabilidad tuya el verificar y consultar constantemente los Términos y Condiciones a efecto de conocer dichos cambios o modificaciones, para lo cual, los mismos estarán disponibles en el Sitio Web y en las páginas, aplicaciones o plataformas que de tiempo en tiempo sean utilizadas, administradas o que sean propiedad de Red Companies para llevar a cabo la prestación de servicios a través de las plataformas de Red Voucher (en lo sucesivo “Red Voucher”). El uso de este servicio mediante la compra de los folios electrónicos a través de internet, significan que usted está de acuerdo con los presentes Términos y Condiciones, así como con las modificaciones o adiciones que sean aplicables y se encuentren vigentes al momento de realizar la compra de los folios electrónicos. </p>
            <p>Al momento de confirmar y realizar la compra de los folios electrónicos, se entiende que el usuario conoce y está de acuerdo expresamente con el contenido, alcance y se adhiere a los presentes Términos y Condiciones y/o a los que en su momento se encuentren vigentes. </p>
            <p>Red Companies hace de tu conocimiento que los servicios de compra de los folios electrónicos a través de Red Voucher están reservados única y exclusivamente para Guatemala. </p>
            <p>Los folios electrónicos únicamente pueden ser comprados usando tu tarjeta de crédito o débito.   </p>
            <p>Los folios electrónicos comprados a través de este sitio estarán disponibles para su descarga y/o impresión. </p>
            <p>Los folios electrónicos comprados a través de este sitio no son reembolsables por dinero, a menos que se sea legalmente requerido. Los folios electrónicos comprados son válidos únicamente para el servicio y/o productos para el cual se adquirieron y no pueden ser cambiados para otro servicio y/o producto. </p>
            <p>Al comprar folios electrónicos a través de este sitio aceptas que: </p>
            <p>Debes de respetar las condiciones establecidas al momento de tu compra. </p>
            <p>Usted está de acuerdo con que podemos negarle la venta de folios electrónicos o retirar la venta de folios electrónicos a través de este sitio. </p>
            <p>Una vez expirada la vigencia del folio electrónico, éstos no podrán ser validados. No se aceptarán reclamos o reembolsos en ningún caso.  </p>
            <p>Todos los precios de los servicios que se expendan a través de Red Voucher y aplicación móvil incluyen IVA. </p>
            <p>Los usuarios que adquieran algún servicio a través de Red Voucher deberán realizar el pago única y exclusivamente mediante tarjetas de crédito / debito bancaria. Red Companies no acepta cambios, cancelaciones, ni devoluciones de compras, en virtud de que el cliente está enterado de las condiciones de uso. </p>
            <p>Para cualquier otra cuestión respecto al servicio de Red Voucher prestado mediante cualquiera de las Plataformas de Red Voucher, favor de comunicarse al correo, soporte@redcompanies.com.gt.</p>
            <p>Cualquier uso, incluyendo los publicitarios, republicación, exhibición, ejecución o comercialización del contenido de este sitio, incluyendo las marcas o avisos comerciales, en alguna red de cómputo o página de Internet se encuentra estrictamente prohibido sin el previo consentimiento por escrito de Red Companies y/o de terceros propietarios de otras marcas o avisos comerciales publicados. </p>
            <p>La marca Red Voucher, así como sus diferentes diseños se encuentran debidamente registrados y están licenciadas a favor de Red Companies o sus afiliadas, por lo que su uso total o parcial está estrictamente prohibido. </p>
            <p>Red Companies (ni sus afiliadas, accionistas, directores o empleados) no se responsabiliza por daños o perjuicios derivados del acceso, uso o mala utilización de los contenidos de este sitio. </p>
            <p>Red Companies no asume responsabilidad alguna con los contenidos y servicios comerciales o de otra índole, aplicaciones o páginas web ajenas a Red Companies que se puedan enlazar electrónicamente (link) directa o indirectamente a través del portal de www.redvoucheronline.com.gt o que manejen información relacionada con el contenido del mismo. </p>
            <p>Red Companies no adquiere ninguna responsabilidad por causa de problemas técnicos que se pudieran producir en equipos informáticos que se utilicen durante la conexión a la red Internet, ya sea a través del presente portal o de otras páginas webs.</p>
            <p>Estos términos y condiciones fueron actualizados por última vez: Febrero 2020</p>
        </div>
    )
}

export default TermsAndConditionsGuatemala;